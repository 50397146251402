@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  background: url("../src/bg.gif") no-repeat;
  background-size: cover;
}

.card {
  background: rgba(211, 235, 255, 0.57);
  backdrop-filter: blur(5px);

}
